<template>
  <div>
    <b-card>
      <div class="m-2">
        <b-row>
          <b-col cols="12" lg="2"> </b-col>

          <b-col cols="12" lg="4" md="4" sm="4" class="mt-2 mb-2">
            <div class="d-flex justify-content-end align-item-center support">
              Support
            </div>
            <div class="d-flex justify-content-end value">
              Value Added Reseller
            </div>
          </b-col>

          <b-col>
            <b-card class="pt-0 card-color">
              <div class="overview">Overview</div>
              <div>
                WALT Labs is a Premier Google Partner, able to resell GCP
                consumption costes & provide Managed Services Support to GCP
                customers.
              </div>
              <div class="value">
                No envrionment need to be modified to work with WALT Labs.
              </div>
            </b-card>
          </b-col>
        </b-row>

        <b-row class="mr-4 ml-3">
          <b-col class="col-auto pr-0">
            <div class="setting-icon">
              <feather-icon
                size="28"
                style="color: white"
                icon="SettingsIcon"
              />
            </div>
          </b-col>

          <b-col>
            <div class="key">Key benefits</div>
            <div class="value mt-2">
              WALT Labs offers two types of Support services
              <span class="feather-info">Standard(free)</span> &
              <span class="feather-info">Premium(paid)</span>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-table
            hover
            :items="tableItems"
            show-empty
            empty-text="No matching records found"
            responsive
            class="mt-3 mr-5 ml-5"
            id="overview"
            outlined
            bordered
            fixed
            no-border-collapse
          >
            <template #head()="data">
              <span v-if="data.label == 'Name'"> {{}} </span>
              <span v-else class="d-flex justify-content-center">
                {{ data.label }}
              </span>
            </template>

            <template #cell(name)="data">
              <span class="d-flex justify-content-center">
                {{ data.item.Name }}
              </span>
            </template>

            <template #cell(standard)="data">
              <span
                class="d-flex justify-content-center"
                v-html="data.item.Standard"
              ></span>
            </template>

            <template #cell(premium)="data">
              <span
                class="d-flex justify-content-center"
                v-html="data.item.Premium"
              ></span>
            </template>
          </b-table>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BSpinner,
  BTable,
  BPagination,
  BFormCheckbox,
} from "bootstrap-vue";
import { matchGrammar } from "prismjs";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BTable,
    BPagination,
    BFormCheckbox,
    Loading,
  },
  data() {
    return {
      tableItems: [
        { Name: "Technical Account Mgr", Standard: "Yes", Premium: "Yes" },
        {
          Name: "Cost Optimisation",
          Standard: "Yes (via Portal)",
          Premium: "Yes",
        },
        { Name: "Support Ticket System", Standard: "Yes", Premium: "Yes" },
        { Name: "Third Party Tech Support", Standard: "No", Premium: "Yes" },
        { Name: "Proactive Monitoring", Standard: "No", Premium: "Yes" },
        { Name: "Guidance to Fix", Standard: "Yes", Premium: "Yes" },
        { Name: "Hands on to Help", Standard: "No", Premium: "Yes" },
        {
          Name: "SLA",
          Standard: "P2: 2 hours<br>P3: 4 hours<br>P4: 4 hours",
          Premium: "P1: 15 mins<br>P2: 1 hours<br>P3: 2 hours<br>P4: 3 hours",
        },
        {
          Name: "Pricing",
          Standard: "Free!<br>(via Billings resell)",
          Premium: "$2,250 per month(£1,850)<br>(10 hours)",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/variables";
.support {
  color: $primary;
  font-size: 40px;
  font-weight: bold;
}
.value {
  font-weight: bold;
}
.card-color {
  background-color: $div-bg;
}
.overview {
  color: $primary;
  font-weight: bold;
  font-size: 1.2rem;
}
.key {
  color: $primary;
  font-weight: bold;
  padding-top: 0.5rem;
  padding-left: 1rem;
  padding-bottom: 1rem;
  border-bottom: 2px dotted;
}
.color {
  color: $primary;
}
.label {
  background-color: $primary;
  color: $white;
}
.setting-icon {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: $primary;
  display: flex;
  justify-content: center;
  align-items: center;
}
#overview {
  thead {
    tr {
      th:nth-child(1) {
        background-color: $white;
        padding-left: 0;
        padding-right: 0;
      }
      th:nth-child(2),
      th:nth-child(3) {
        background-color: $primary;
        color: $white;
      }
    }
  }
}
tbody {
  tr {
    td:nth-child(1) {
      background-color: $overview-bg;
      color: $black;
      font-weight: bold;
      padding-left: 0;
      padding-right: 0;
    }
    td:nth-child(2),
    td:nth-child(3) {
      font-weight: bold;
      text-align: center;
    }
  }
}
</style>
